@tailwind base;
@tailwind components;
@tailwind utilities;

.hero-gradient {
    background-image: linear-gradient(45deg,
            hsl(349deg 77% 54%) 0%,
            hsl(336deg 77% 48%) 34%,
            hsl(325deg 75% 43%) 67%,
            hsl(311deg 62% 40%) 100%);
}